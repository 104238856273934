<template>
  <div class="wizard-progress my-4">
    <div class="step complete">
      CSR Validation
      <div class="node" @click="directClick(1)">1</div>
    </div>
    <div
      class="step"
      :class="step === 2 || step === 3 ? 'complete' : ''"
      @click="directClick(2)"
    >
      Domain Validation
      <div class="node">2</div>
    </div>
    <div
      class="step"
      :class="step === 3 ? 'complete' : ''"
      @click="directClick(3)"
    >
      Contact Validation
      <div class="node">3</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "widgetWizardSSL",
  props: {
    step: {
      required: true
    }
  },
  methods: {
    /**
     * --------------------------------------------------
     * dikirim ke parent trigger direct click step
     * --------------------------------------------------
     */
    directClick: function(step) {
      this.$emit("stepClick", step);
    }
  }
  // computed:{
  //   /**
  //    * --------------------------------------------------
  //    * dibuat computed karna ada peringatan jika menggunakan step directly
  //    * --------------------------------------------------
  //    */
  //   langkah: function () {
  //     return this.step
  //   }
  // }
};
</script>

<style scoped>
.wizard-progress {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
  height: 5rem;
}
.wizard-progress .step {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  font-size: 14px;
  font-weight: bold;
}
.wizard-progress .step:not(:last-child):before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 37px;
  background-color: lightgray;
  height: 6px;
  width: 100%;
}
.wizard-progress .step .node {
  display: inline-block;
  border: 3px solid lightgray;
  background-color: #fff;
  border-radius: 18px;
  /*height: 18px;*/
  width: 32px;
  position: absolute;
  top: 25px;
  left: 50%;
  margin-left: -18px;
  cursor: pointer;
}
.wizard-progress .step.complete:before {
  background-color: #07c;
}
.wizard-progress .step.complete .node {
  border-color: #07c;
  background-color: #07c;
}

.wizard-progress .step.in-progress:before {
  background: #07c;
  background: -moz-linear-gradient(left, #07c 0%, #fff 100%);
  background: -webkit-linear-gradient(left, #07c 0%, #fff 100%);
  background: linear-gradient(to right, #07c 0%, #fff 100%);
}
.wizard-progress .step.in-progress .node {
  border-color: #07c;
}
.complete .node {
  color: white;
}
</style>
