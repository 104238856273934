<template>
  <RWCard>
    <template #title>
      <widgetWizardSSL :step="step"></widgetWizardSSL>
    </template>
    <template #body>
      <template v-if="step === 1">
        <div class="alert alert-warning">
          You must have a valid "CSR" (Certificate Signing Request) to configure
          your SSL Certificate. The CSR is an encrypted piece of text that is
          generated by the web server where the SSL Certificate will be
          installed. If you do not already have a CSR, you must generate one or
          ask your web hosting provider to generate one for you.
        </div>
        <form action="">
          <div class="form-group">
            <label>CSR (Certificate Signing Request) :</label>
            <b-form-textarea
              rows="7"
              placeholder="-----BEGIN CERTIFICATE REQUEST-----

-----END CERTIFICATE REQUEST-----"
            ></b-form-textarea>
            <span class="text-muted"
              >PS: Jika Common Name di CSR merupakan naked domain, pastikan
              Common Name menggunakan subdomain www.</span
            >
          </div>

          <button type="button" @click="step = 2" class="btn btn-primary">
            Lanjutkan <i class="fa fa-angle-double-right"></i>
          </button>
        </form>
      </template>

      <!--step 2-->
      <template v-if="step === 2">
        <div class="alert alert-warning">
          Silahkan pilih salah satu opsi dibawah ini. CA akan melakukan validasi
          domain berdasarkan pilihan yang Anda gunakan.
        </div>
        <div class="row">
          <div class="col-8 offset-2">
            <form>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Pilih Metode Validasi</label
                >
                <div class="col-sm-8">
                  <b-form-select
                    v-model="metode_validasi.selected"
                    :options="metode_validasi.options"
                  ></b-form-select>
                </div>
              </div>

              <div
                class="form-group row"
                v-if="metode_validasi.selected === 'email'"
              >
                <b-alert variant="secondary" show>
                  Silakan pilih salah satu email berikut untuk melewati proses
                  validasi domain. Pastikan email <code>ada</code> dan
                  <code>bisa diakses</code> dengan baik. Gunakan metode validasi
                  lainnya jika Anda tidak memiliki email yang tersedia pada
                  pilihan dibawah ini
                </b-alert>
                <label class="col-sm-4 col-form-label"
                  >Pilih Penerima Email</label
                >
                <div class="col-sm-8">
                  <b-form-select
                    v-model="penerima_email.selected"
                    :options="penerima_email.options"
                  ></b-form-select>
                </div>
              </div>

              <b-alert
                variant="secondary"
                show
                v-if="
                  metode_validasi.selected === 'cname' ||
                    metode_validasi.selected === 'http'
                "
              >
                <p v-if="metode_validasi.selected === 'cname'">
                  Jika Anda tidak bisa melakukan validasi domain menggunakan
                  email, Anda dapat membuktikan kepemilikan domain Anda dengan
                  membuat Record DNS. Untuk menggunakan metode ini, Anda harus
                  dapat mengedit DNS domain untuk membuat catatan baru dengan
                  tipe TXT, dengan nilai unik yang akan kami berikan nanti.
                </p>
                <p v-if="metode_validasi.selected === 'http'">
                  CA (Certificate Authority) akan membuat kode verifikasi
                  spesifik untuk domain Anda. Letakkan kode verifikasi tersebut
                  di sebuah file teks yang sudah ditentukan. Pastikan file teks
                  tersebut bisa diakses lewat http.
                </p>
              </b-alert>

              <p class="text-center mt-3">
                <input
                  type="button"
                  value="« Kembali"
                  class="btn btn-default"
                  @click="step = 1"
                />&nbsp;
                <input
                  type="button"
                  @click="step = 3"
                  value="Lanjutkan »"
                  class="btn btn-primary"
                />
              </p>
            </form>
          </div>
        </div>
      </template>

      <!--step 3-->
      <template v-if="step === 3">
        <b-alert show variant="warning">
          Below entries are required by the SSL issuer. However you can skip the
          entries now and complete them later. SSL will not be submitted to the
          issuer until all entries are completed.
        </b-alert>

        <form action="">
          <h4>Administrative Contact Details</h4>
          <p>
            The administrative contact is the primary contact and will be
            contacted to assist in resolution of any questions about the order.
            Administrative person will be also used for Verification Callbacks,
            that will be made for all Business/Extended Validation certificates.
            No callbacks will be made for Domain Validation Certificates.
          </p>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Nama Depan</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="First Name"
              />
            </div>
            <label class="col-sm-2 col-form-label">Nama Akhir</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Last Name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Email Address"
              />
            </div>
            <label class="col-sm-2 col-form-label">Nama Perusahaan</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Organization Name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Pekerjaan</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Job Title"
              />
            </div>
            <label class="col-sm-2 col-form-label">Alamat</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Address"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Kota</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="City"
              />
            </div>
            <label class="col-sm-2 col-form-label">Provinsi</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="State/Region"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Kode Pos</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Zip Code"
              />
            </div>
            <label class="col-sm-2 col-form-label">Negara</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Country"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Handphone</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Phone Number"
              />
            </div>
          </div>
          <hr />

          <h4>Technical Contact Details</h4>
          <p>
            The Technical contact will receive the certificate and generally be
            the individual to install the certificate on the web server.
            Technical contact will also receive renewal notices.
          </p>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Nama Depan</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="First Name"
              />
            </div>
            <label class="col-sm-2 col-form-label">Nama Akhir</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Last Name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Email Address"
              />
            </div>
            <label class="col-sm-2 col-form-label">Nama Perusahaan</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Organization Name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Pekerjaan</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Job Title"
              />
            </div>
            <label class="col-sm-2 col-form-label">Alamat</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Address"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Kota</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="City"
              />
            </div>
            <label class="col-sm-2 col-form-label">Provinsi</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="State/Region"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Kode Pos</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Zip Code"
              />
            </div>
            <label class="col-sm-2 col-form-label">Negara</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Country"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Handphone</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Phone Number"
              />
            </div>
          </div>

          <p class="text-center">
            <input
              type="button"
              value="« Back"
              class="btn btn-default"
              @click="step = 2"
            />&nbsp;
            <input type="submit" value="Continue »" class="btn btn-primary" />
          </p>
        </form>
      </template>
    </template>
  </RWCard>
</template>

<script>
import widgetWizardSSL from "@/views/partials/widgets/rw/widgetWizardSSL";
import RWCard from "@/views/partials/content/Portlet.vue";

export default {
  name: "globalsign-index",
  components: { RWCard, widgetWizardSSL },
  data() {
    return {
      step: 3,
      metode_validasi: {
        selected: "",
        options: [
          { value: "", text: "-Pilih-" },
          { value: "email", text: "E-MAIL Validasi (recommended)" },
          { value: "cname", text: "CNAME" },
          { value: "http", text: "HTTP" }
        ]
      },
      penerima_email: {
        selected: "admin@addin.my.id",
        options: [
          { value: "admin@addin.my.id", text: "admin@addin.my.id" },
          {
            value: "administrator@addin.my.id",
            text: "administrator@addin.my.id"
          },
          { value: "hostmaster@addin.my.id", text: "hostmaster@addin.my.id" },
          { value: "postmaster@addin.my.id", text: "postmaster@addin.my.id" },
          { value: "webmaster@addin.my.id", text: "webmaster@addin.my.id" }
        ]
      }
    };
  }
};
</script>

<style scoped></style>
