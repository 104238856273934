<template>
  <div class="row">
    <!-- side bar -->
    <div class="col-sm-3">
      <RWCard title="Status" :body-fit="true">
        <template v-slot:body>
          <b-alert show variant="danger" v-if="status.code">{{
            status.message
          }}</b-alert>
          <div v-else>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>
                    <strong :class="color">{{ status.status }}</strong>
                  </td>
                </tr>
                <tr v-if="status.expired">
                  <td>Expire Date</td>
                  <td>:</td>
                  <td>
                    <strong>{{ status.expired }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>:</td>
                  <td>
                    <strong>{{ status.duedate }}</strong>
                  </td>
                </tr>
                <tr v-if="status.billingcycle">
                  <td>Billing Cycle</td>
                  <td>:</td>
                  <td>
                    <strong>{{ status.billingcycle }}</strong>
                  </td>
                </tr>
                <tr v-if="status.billingcycle">
                  <td>Billing Cycle</td>
                  <td>:</td>
                  <td>
                    <strong :class="color">{{ status.billingcycle }}</strong>
                  </td>
                </tr>
                <tr v-if="status.recurring">
                  <td>Billing Cycle</td>
                  <td>:</td>
                  <td>
                    <strong>{{ status.recurring }}</strong>
                  </td>
                </tr>
                <tr v-if="status.recurring">
                  <td>Price</td>
                  <td>:</td>
                  <td>
                    <strong>Rp{{ status.price }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </RWCard>
    </div>

    <!-- right bar -->
    <div class="col-sm-9">
      <RWCard :title="status.name ? status.name : status.domain">
        <template #body>
          <b-alert show variant="danger" v-if="status.code">{{
            status.message
          }}</b-alert>

          <!-- status success 200 -->
          <div v-else>
            <!-- tidak upload doc -->
            <div v-if="!status.document">
              <p class="text-center">
                Anda tidak dapat mengelola layanan ini, <br />
                karena layanan Anda dalam status
                <span :class="color" class="font-weight-bold text-lowercase"
                  >{{ status.status }}.</span
                >
              </p>
            </div>

            <!-- jika harus upload doc -->
            <div v-else>
              <!-- loading skeleton upload doc -->
              <div v-if="document_loading">
                <div class="row">
                  <div class="col-sm-6">
                    <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                  </div>
                  <div class="col-sm-6">
                    <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                  </div>
                  <div class="col-sm-12 mt-3">
                    <v-skeleton-loader type="text@4"></v-skeleton-loader>
                  </div>
                </div>
              </div>

              <!-- selesai loading doc -->
              <div v-else>
                <!-- doc gagal request response !=200 -->
                <div v-if="!document_success">
                  <div class="alert alert danger">{{ document.message }}</div>
                </div>

                <!-- document sukses request 200-->
                <b-row v-else>
                  <b-col sm="5">
                    <h5>
                      Domain Status : <small>{{ document.domainstatus }}</small>
                    </h5>

                    <h5 class="mt-3">Upload Document</h5>
                    <b-link
                      class="btn btn-success btn-sm"
                      :href="document.docurl"
                      target="_blank"
                    >
                      <i class="fa fa-upload"></i> Upload
                    </b-link>
                  </b-col>
                  <b-col sm="7">
                    <h5 class="mt-3 mt-sm-0">Document Status:</h5>
                    <ul class="ml-4">
                      <li
                        v-for="(doc, key) in document.requirement"
                        :key="key"
                        class="my-1"
                      >
                        {{ doc.type }} :
                        <span
                          v-html="doc_keterangan(doc.status, doc.documentid)"
                        ></span>
                      </li>
                    </ul>
                  </b-col>

                  <!-- notification -->
                  <b-col sm="12" class="my-3">
                    <hr />
                    <h5>Notification :</h5>
                    <div
                      v-for="(notif, key) in document.notification"
                      :key="key"
                    >
                      <b-alert
                        show
                        :variant="
                          notif.senderid === 'registrar' ? 'info' : 'warning'
                        "
                      >
                        <h6>{{ notif.date }} {{ notif.sender }}</h6>
                        <p>{{ notif.notif }}</p>
                      </b-alert>
                    </div>
                    <b-link
                      :href="document.notifurl"
                      target="_blank"
                      class="btn btn-success"
                      >Reply</b-link
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </template>
      </RWCard>
    </div>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { productMixin } from "@/common/mixins/product/product-mixin.js";
import { util, ApiURL } from "@/common/mixins/general.js";

export default {
  name: "slotLayananNonActive",
  mixins: [productMixin, util, ApiURL],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      document: "",
      document_success: false,
      document_loading: true
    };
  },
  components: {
    RWCard
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * request doc jika status document = true
     * --------------------------------------------------------------
     */
    get_domain_id: function() {
      this.http
        .get(this.end.domain.docdetail, this.status.id)
        .then(({ data }) => {
          this.document = data.data.detail;
          this.document_success = true;
        })
        .catch(error => {
          this.document = error;
        })
        .finally(() => {
          this.document_loading = false;
        });
    },

    /**
     * --------------------------------------------------------------
     * status combinasi
     * status | docid | keterangan
     * --------------------------------
     *    0   |   0   | incomplite
     *    0   |   1   |  Pending Validation
     *    1   |   1   | valid
     * --------------------------------------------------------------
     */

    doc_keterangan: function(status, docid) {
      if (!status && !docid) {
        return "<span class='badge badge-danger'>Incomplete</span>";
      } else if (!status && docid) {
        return "<span class='badge badge-warning'>Pending Validation</span>";
      } else if (status && docid) {
        return "<span class='badge badge-success'>Valid</span>";
      } else {
        return "<span class='badge badge-danger'>Error</span>";
      }
    }
  },
  computed: {
    color() {
      return this.mx_domain_color(this.status.status);
    }
  },
  mounted() {
    this.get_domain_id();
  }
};
</script>
