<template>
  <RWCard>
    <template #title>
      <widgetWizardSSL :step="step"></widgetWizardSSL>
    </template>
    <template #body>
      <!--step 1-->
      <div v-if="step === 1">
        <div class="alert alert-warning">
          Anda harus memiliki
          <strong>CSR (Certificate Signing Request)</strong> yang valid untuk
          mengonfigurasi Sertifikat SSL Anda. <br />CSR adalah blok teks yang
          disandikan yang diberikan kepada Otoritas Sertifikat ketika mengajukan
          permohonan Sertifikat SSL. <br />Jika anda belum memiliki CSR, anda
          harus membuatnya atau meminta penyedia hosting web anda untuk membantu
          membuatnya.
        </div>

        <form>
          <div class="form-group">
            <label>Type Webserver :</label>
            <b-form-select
              v-model="web_server.selected"
              :options="web_server.options"
            ></b-form-select>
          </div>
          <div class="form-group">
            <label>CSR (Certificate Signing Request) :</label>
            <b-form-textarea rows="7"></b-form-textarea>
          </div>

          <button type="button" @click="step = 2" class="btn btn-primary">
            Lanjutkan <i class="fa fa-angle-double-right"></i>
          </button>
        </form>
      </div>

      <!--step 2-->
      <div v-if="step === 2">
        <div class="alert alert-warning">
          Silakan pilih salah satu email berikut untuk melewati proses validasi
          domain. Pastikan email ada dan bisa diakses dengan baik.
        </div>
        <form class="form-horizontal" method="post">
          <div class="form-group">
            <label class="control-label col-xs-4">Pilih Penerima Email</label>
            <div class="col-xs-6">
              <b-form-select
                v-model="penerima_email.selected"
                :options="penerima_email.options"
              ></b-form-select>
            </div>
          </div>

          <p class="text-center">
            <input
              type="button"
              value="« Kembali"
              class="btn btn-default"
              onclick="history.go(-1)"
            />&nbsp;
            <input
              type="button"
              @click="step = 3"
              value="Lanjutkan »"
              class="btn btn-primary"
            />
          </p>
        </form>
      </div>

      <!--step 3-->
      <div v-if="step === 3">
        <div class="alert alert-warning">
          Point of Contact akan menerima Sertifikat yang sudah terbit, serta
          pemberitahuan jika sertifikat sudah mendekati kadaluarsa. Kontak ini
          juga akan menjadi pihak yang dihubungi oleh
          <b>CA (Certificate Authority)</b> jika diperlukan verifikasi dokumen
          dan keperluan terkait sertifikat lainnya.
        </div>

        <form>
          <h4 class="mt-5">KONTAK PERSONAL</h4>
          <hr />
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Nama Lengkap</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-user"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Full Name"
                />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">E-mail</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-envelope-open"></i
                  ></span>
                </div>
                <input
                  type="email"
                  class="form-control"
                  required
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Negara</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-flag"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Country"
                />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Handphone</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-phone-square-alt"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Telepon</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-phone-alt"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>

          <h4 class="mt-5">PERUSAHAAN</h4>
          <hr />
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Nama Perusahaan</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-industry"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Company Name"
                />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Nomor Usaha (NIB)</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-store"></i
                  ></span>
                </div>
                <input type="text" class="form-control" required />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Departemen</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-tag"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Department"
                />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Posisi / Jabatan</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-project-diagram"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Position"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Kode Pos</label>
            <div class="col-sm-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-map-signs"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Postal Code"
                />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Alamat</label>
            <div class="col-sm-4">
              <textarea
                rows="4"
                class="form-control"
                required
                placeholder="Address"
              ></textarea>
            </div>
          </div>
        </form>

        <p class="text-center my-4 mt-3">
          <input
            type="button"
            value="« Kembali"
            class="btn btn-default"
            onclick="history.go(-1)"
          />&nbsp;
          <input type="submit" value="Lanjutkan »" class="btn btn-primary" />
        </p>
      </div>
    </template>
  </RWCard>
</template>

<script>
import widgetWizardSSL from "@/views/partials/widgets/rw/widgetWizardSSL";
import RWCard from "@/views/partials/content/Portlet.vue";
export default {
  name: "entrust-index",
  components: { RWCard, widgetWizardSSL },
  data() {
    return {
      step: 1,
      web_server: {
        selected: "31",
        options: [
          { value: "1", text: "OL" },
          { value: "2", text: "Apache/ModSSL" },
          { value: "3", text: "Apache-SSL (Ben-SSL, not Stronghold)" },
          { value: "4", text: "C2Net Stronghold" },
          { value: "5", text: "Cobalt Raq" },
          { value: "6", text: "Covalent Server Software" },
          { value: "7", text: "IBM HTTP Server" },
          { value: "8", text: "IBM Internet Connection Server" },
          { value: "9", text: "iPlanet" },
          { value: "10", text: "Java Web Server (Javasoft / Sun)" },
          { value: "11", text: "Lotus Domino" },
          { value: "12", text: "Lotus Domino Go!" },
          { value: "13", text: "Microsoft IIS 1.x to 4.x" },
          { value: "14", text: "Microsoft IIS 5.x to 6.x" },
          { value: "15", text: "Netscape Enterprise Server" },
          { value: "16", text: "Netscape FastTrack" },
          { value: "17", text: "Novell Web Server" },
          { value: "18", text: "Oracle" },
          { value: "19", text: "Quid Pro Quo" },
          { value: "20", text: "R3 SSL Server" },
          { value: "21", text: "Raven SSL" },
          { value: "22", text: "RedHat Linux" },
          { value: "23", text: "SAP Web Application Server" },
          { value: "24", text: "Tomcat" },
          { value: "25", text: "Website Professional" },
          { value: "26", text: "WebStar 4.x and later" },
          { value: "27", text: "WebTen (from Tenon)" },
          { value: "28", text: "Zeus Web Server" },
          { value: "29", text: "Ensim" },
          { value: "30", text: "Plesk" },
          { value: "31", text: "WHM/cPanel" },
          { value: "32", text: "H-Sphere" },
          { value: "33", text: "Cisco 3000 Series VPN Concentrator" },
          { value: "34", text: "Citrix" },
          { value: "35", text: "Microsoft IIS 7.x and later" },
          { value: "71", text: "WebtoB" },
          { value: "72", text: "NginX" }
        ]
      },
      penerima_email: {
        selected: "admin@addin.com",
        options: [
          { value: "admin@addin.com", text: "admin@addin.com" },
          { value: "administrator@addin.com", text: "administrator@addin.com" },
          { value: "hostmaster@addin.com", text: "hostmaster@addin.com" },
          { value: "postmaster@addin.com", text: "postmaster@addin.com" },
          { value: "webmaster@addin.com", text: "webmaster@addin.com" }
        ]
      }
    };
  }
};
</script>
