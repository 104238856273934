<template>
  <div>
    <RWCard v-if="status_request === 'awaiting configuration'">
      <template #title>
        <widgetWizardSSL
          :step="step"
          @stepClick="clickStepDirect"
        ></widgetWizardSSL>
      </template>
      <template #body>
        <!--step 1-->
        <template v-if="step === 1">
          <div class="alert alert-warning">
            Anda harus memiliki "CSR" (Certificate Signing Request) yang valid
            untuk mengonfigurasi Sertifikat SSL Anda. CSR adalah blok teks yang
            disandikan yang diberikan kepada Otoritas Sertifikat ketika
            mengajukan permohonan Sertifikat SSL. Jika anda belum memiliki CSR,
            anda harus membuatnya atau meminta penyedia hosting web anda untuk
            membantu membuatnya.
          </div>
          <form>
            <div class="form-group">
              <label>Type Webserver :</label>
              <b-form-select
                v-model="form.step1.web_server"
                :options="web_server.options"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label>CSR (Certificate Signing Request) :</label>
              <b-form-textarea
                rows="7"
                v-model="form.step1.csr"
              ></b-form-textarea>
            </div>

            <p class="text-center">
              <button
                type="button"
                @click="submitStepOne"
                class="btn btn-primary"
              >
                Lanjutkan <i class="fa fa-angle-double-right"></i>
              </button>
            </p>
          </form>
        </template>

        <!--step 2-->
        <template v-if="step === 2">
          <div class="alert alert-warning">
            Silahkan pilih salah satu opsi dibawah ini. CA akan melakukan
            validasi domain berdasarkan pilihan yang Anda gunakan.
          </div>
          <div class="row">
            <div class="col-8 offset-2">
              <form>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Pilih Metode Validasi</label
                  >
                  <div class="col-sm-8">
                    <b-form-select
                      v-model="form.step2.metode_validasi"
                      :options="metode_validasi.options"
                    ></b-form-select>
                  </div>
                </div>

                <div
                  class="form-group row"
                  v-if="form.step2.metode_validasi === 'EMAIL'"
                >
                  <b-alert variant="secondary" show>
                    Silakan pilih salah satu email berikut untuk melewati proses
                    validasi domain. Pastikan email <code>ada</code> dan
                    <code>bisa diakses</code> dengan baik. Gunakan metode
                    validasi lainnya jika Anda tidak memiliki email yang
                    tersedia pada pilihan dibawah ini
                  </b-alert>
                  <label class="col-sm-4 col-form-label"
                    >Pilih Penerima Email</label
                  >
                  <div class="col-sm-8">
                    <b-form-select
                      v-model="form.step2.penerima_email"
                      :options="penerima_email.options"
                    ></b-form-select>
                  </div>
                </div>

                <b-alert
                  variant="secondary"
                  show
                  v-if="
                    form.step2.metode_validasi === 'CNAME' ||
                      form.step2.metode_validasi === 'HTTP'
                  "
                >
                  <p v-if="form.step2.metode_validasi === 'CNAME'">
                    Jika Anda tidak bisa melakukan validasi domain menggunakan
                    email, Anda dapat membuktikan kepemilikan domain Anda dengan
                    membuat Record DNS. Untuk menggunakan metode ini, Anda harus
                    dapat mengedit DNS domain untuk membuat catatan baru dengan
                    tipe TXT, dengan nilai unik yang akan kami berikan nanti.
                  </p>
                  <p v-if="form.step2.metode_validasi === 'HTTP'">
                    CA (Certificate Authority) akan membuat kode verifikasi
                    spesifik untuk domain Anda. Letakkan kode verifikasi
                    tersebut di sebuah file teks yang sudah ditentukan. Pastikan
                    file teks tersebut bisa diakses lewat http.
                  </p>
                </b-alert>

                <p class="text-center mt-3">
                  <input
                    type="button"
                    value="« Kembali"
                    class="btn btn-default"
                    @click="step = 1"
                  />&nbsp;
                  <input
                    type="button"
                    @click="submitStepTwo"
                    value="Lanjutkan »"
                    class="btn btn-primary"
                  />
                </p>
              </form>
            </div>
          </div>
        </template>

        <!--step 3-->
        <template v-if="step === 3">
          <b-alert variant="warning" show>
            Point of Contact akan menerima Sertifikat yang sudah terbit, serta
            pemberitahuan jika sertifikat sudah mendekati kadaluarsa. Kontak ini
            juga akan menjadi pihak yang dihubungi oleh CA (Certificate
            Authority) jika diperlukan verifikasi dokumen dan keperluan terkait
            sertifikat lainnya.
          </b-alert>
          <form>
            <h4 class="mt-5">KONTAK</h4>
            <hr />
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Nama Lengkap</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-user"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    required
                    placeholder="Full Name"
                    v-model="form.step3.name"
                  />
                </div>
              </div>
              <label class="col-sm-2 col-form-label">E-mail</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-envelope-open-text"></i
                    ></span>
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    required
                    placeholder="Email"
                    v-model="form.step3.email"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Negara</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-flag"></i
                    ></span>
                  </div>
                  <b-form-select
                    :options="country"
                    v-model="form.step3.dialcode"
                  ></b-form-select>
                </div>
              </div>
              <label class="col-sm-2 col-form-label">Telepon</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text font-weight-bold text-success"
                      >{{ form.step3.dialcode }}</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    required
                    placeholder="Phone Number"
                    v-model="form.step3.telepon"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="account.configoption1 !== 'DV'">
              <label class="col-sm-2 col-form-label">Handphone</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text font-weight-bold text-success"
                      >{{ form.step3.dialcode }}</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    required
                    placeholder="Phone Number"
                    v-model="form.step3.phone"
                  />
                </div>
              </div>
            </div>

            <h4 class="mt-5" v-if="account.configoption1 !== 'DV'">
              PERUSAHAAN
            </h4>
            <h4 class="mt-5" v-else>TAMBAHAN</h4>
            <hr />
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Nama Perusahaan</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-industry"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    required
                    placeholder="Company Name"
                    v-model="form.step3.company"
                  />
                </div>
              </div>
              <template v-if="account.configoption1 !== 'DV'">
                <label class="col-sm-2 col-form-label">Nomor Usaha (NIB)</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-store"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Business Number"
                      v-model="form.step3.no_usaha"
                    />
                  </div>
                </div>
              </template>
              <template v-if="account.configoption1 === 'DV'">
                <label class="col-sm-2 col-form-label">Kode Pos</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-map-signs"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Postal Code"
                      v-model="form.step3.pos_code"
                    />
                  </div>
                </div>
              </template>
            </div>
            <div class="form-group row" v-if="account.configoption1 !== 'DV'">
              <label class="col-sm-2 col-form-label">Departemen</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-tag"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    required
                    placeholder="Department"
                    v-model="form.step3.departemen"
                  />
                </div>
              </div>
              <label class="col-sm-2 col-form-label">Jabatan</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-project-diagram"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    required
                    placeholder="Position"
                    v-model="form.step3.jabatan"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <template v-if="account.configoption1 !== 'DV'">
                <label class="col-sm-2 col-form-label">Kode Pos</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-map-signs"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Postal Code"
                      v-model="form.step3.pos_code"
                    />
                  </div>
                </div>
              </template>

              <label class="col-sm-2 col-form-label">Alamat</label>
              <div class="col-sm-4">
                <textarea
                  rows="4"
                  class="form-control"
                  required
                  placeholder="Address"
                  v-model="form.step3.address"
                ></textarea>
              </div>
            </div>
          </form>

          <p class="text-center my-4 mt-3">
            <input
              type="button"
              value="« Kembali"
              class="btn btn-default"
              @click="step = 2"
            />&nbsp;
            <input
              type="button"
              @click="submitStepThree"
              value="Submit"
              class="btn btn-primary"
            />
          </p>
        </template>
      </template>
    </RWCard>

    <!-- untuk PENDING VALIDATION  or ISSUED -->
    <div
      class="row"
      v-if="
        status_request === 'pending validation' || status_request === 'issued'
      "
    >
      <div class="col-sm-4 col-12">
        <RWCard title="Informasi" :body-fit="true">
          <template #body>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>Domain</td>
                  <td>:</td>
                  <td>{{ status.domain }}</td>
                </tr>
                <tr>
                  <td>Package</td>
                  <td>:</td>
                  <td>{{ status.name }}</td>
                </tr>
                <tr>
                  <td>Periode</td>
                  <td>:</td>
                  <td>
                    {{ mx_recurring_to_number(status.recurring) }}
                  </td>
                </tr>
                <tr v-if="account.vendor !== null">
                  <td>Metode Validasi</td>
                  <td>:</td>
                  <td>
                    {{ account.vendor.dcvmethod }}
                  </td>
                </tr>
                <tr>
                  <td>Status Request</td>
                  <td>:</td>
                  <td v-if="account.vendor !== null">
                    <div
                      v-html="
                        mx_badge_ssl_status_request(
                          account.vendor.status_request
                        )
                      "
                    ></div>
                  </td>
                  <td v-else>
                    <span class="badge badge-danger"
                      >Awaiting Configuration</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </RWCard>
      </div>
      <div class="col-sm-8 col-12">
        <!-- set configurasi -->
        <div class="col-sm-12">
          <RWCard
            :title="`Konfigurasi ${account.vendor.dcvmethod}`"
            v-if="status_request === 'pending validation'"
          >
            <template #toolbar>
              <b-button v-b-modal.modal-1 variant="warning"
                ><i class="fa fa-exchange-alt"></i> Ganti Metode
                Validasil</b-button
              >
            </template>
            <template #body>
              <!-- METHOD VALIDASI EMAIL -->
              <div v-if="account.vendor.dcvmethod === 'EMAIL'">
                <p>
                  Email Anda
                  <b class="font-weight-bold">{{ account.vendor.dcvemail }}</b>
                  akan menerima pesan dari CA (sectigo) untuk konfirmasi bahwa
                  {{ status.domain }} benar milik Anda.
                </p>
                <p>
                  Pastikan email tersebut aktif dan bisa diakses, atau Anda
                  dapat mengubah metode validasi dengan klik button yang sudah
                  disediakan.
                </p>
              </div>

              <!-- METHOD VALIDASI HTTP -->
              <div v-if="account.vendor.dcvmethod === 'HTTP'">
                <!--<p>Silahkan lakukan validasi domain dengan cara sebagai berikut : </p>-->

                <div class="card">
                  <div class="card-header">
                    Buatlah file <b class="font-weight-bold">TXT</b> sesuai path
                  </div>
                  <div class="card-body">
                    <b-skeleton
                      width="100%"
                      v-if="config_dcvmethod.loading"
                    ></b-skeleton>
                    <template v-else>
                      <small>{{ config_dcvmethod.HTTP.path }}</small>
                    </template>
                  </div>
                </div>
                <div class="card mt-2">
                  <div class="card-header">
                    Didalam file <b class="font-weight-bold">TXT</b> harus
                    memuat data
                  </div>
                  <div class="card-body">
                    <template v-if="config_dcvmethod.loading">
                      <b-skeleton
                        width="80%"
                        v-if="config_dcvmethod.loading"
                      ></b-skeleton>
                      <b-skeleton
                        width="20%"
                        v-if="config_dcvmethod.loading"
                      ></b-skeleton>
                    </template>
                    <template v-else>
                      {{ config_dcvmethod.HTTP.line1 }}
                      {{ config_dcvmethod.HTTP.line2 }}
                    </template>
                  </div>
                </div>
              </div>

              <!-- METHOD VALIDASI CNAME -->
              <div v-if="account.vendor.dcvmethod === 'CNAME'">
                <p>Silahkan buat DNS record CNAME dengan data berikut:</p>

                <h6>Domain</h6>
                <b-skeleton
                  width="80%"
                  v-if="config_dcvmethod.loading"
                ></b-skeleton>
                <p v-else>
                  <code>{{ config_dcvmethod.CNAME.cname }}</code> &nbsp;
                  <span
                    v-b-tooltip.hover
                    title="Copy"
                    class="far fa-clone"
                    @click="clone(config_dcvmethod.CNAME.cname, 'CNAME')"
                    style="cursor: pointer"
                  ></span>
                </p>
                <v-divider></v-divider>
                <h6>Record</h6>
                <b-skeleton
                  width="90%"
                  v-if="config_dcvmethod.loading"
                ></b-skeleton>
                <p v-else>
                  <code>{{ config_dcvmethod.CNAME.cname_record }}</code> &nbsp;
                  <span
                    v-b-tooltip.hover
                    title="Copy record"
                    class="far fa-clone"
                    @click="
                      clone(config_dcvmethod.CNAME.cname_record, 'CNAME Record')
                    "
                    style="cursor: pointer"
                  ></span>
                </p>
              </div>
            </template>
          </RWCard>

          <!-- download crt when issued -->
          <RWCard
            v-if="status_request === 'issued'"
            title="Download Certificate"
          >
            <template #toolbar>
              <b-button v-b-modal.modal-1 variant="warning"
                ><i class="fa fa-retweet"></i> Re-issue</b-button
              >
            </template>
            <template #body>
              <p>Silahkan download sertifikat</p>

              <template v-if="cert.loading">
                <div class="row">
                  <div class="col">
                    <b-skeleton type="button" width="100%"></b-skeleton>
                  </div>
                  <div class="col">
                    <b-skeleton type="button" width="100%"></b-skeleton>
                  </div>
                  <div class="col">
                    <b-skeleton type="button" width="100%"></b-skeleton>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row" v-if="cert.success">
                  <div class="col">
                    <button
                      class="btn btn-outline-dark btn-block"
                      @click="downloadCert('crt')"
                    >
                      <i class="fa fa-download"></i> CRT
                    </button>
                  </div>
                  <div class="col">
                    <button
                      class="btn btn-outline-dark btn-block"
                      @click="downloadCert('intermediate')"
                    >
                      <i class="fa fa-download"></i> Intermediate
                    </button>
                  </div>
                  <div class="col">
                    <button
                      class="btn btn-outline-dark btn-block"
                      @click="downloadCert('root')"
                    >
                      <i class="fa fa-download"></i> ROOT
                    </button>
                  </div>
                </div>
                <div class="alert alert-danger" v-else>
                  {{ cert.errorMessage }}
                </div>
              </template>

              <p class="mt-4">
                Jika Anda kehilangan private key CSR, silahkan generate ulang
                CSR dan lakukan re-issue dengan klik button
                <span class="badge badge-warning"
                  ><i class="fa fa-retweet"></i> Re-issue</span
                >.
              </p>
            </template>
          </RWCard>
        </div>
      </div>

      <!-- modal change method validation -->
      <b-modal
        id="modal-1"
        title="Ganti Metode Validasi"
        ok-title="Submit"
        @ok="changeDcvMethod"
      >
        <form>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Pilih Metode Validasi</label>
            <div class="col-sm-8">
              <b-form-select
                v-model="form.step2.metode_validasi"
                :options="metode_validasi.options"
              ></b-form-select>
            </div>
          </div>

          <div
            class="form-group row"
            v-if="form.step2.metode_validasi === 'EMAIL'"
          >
            <b-alert variant="secondary" show>
              Silakan pilih salah satu email berikut untuk melewati proses
              validasi domain. Pastikan email <code>ada</code> dan
              <code>bisa diakses</code> dengan baik. Gunakan metode validasi
              lainnya jika Anda tidak memiliki email yang tersedia pada pilihan
              dibawah ini
            </b-alert>
            <label class="col-sm-4 col-form-label">Pilih Penerima Email</label>
            <div class="col-sm-8">
              <b-form-select
                v-model="form.step2.penerima_email"
                :options="penerima_email.options"
              ></b-form-select>
            </div>
          </div>

          <b-alert
            variant="secondary"
            show
            v-if="
              form.step2.metode_validasi === 'CNAME' ||
                form.step2.metode_validasi === 'HTTP'
            "
          >
            <p v-if="form.step2.metode_validasi === 'CNAME'">
              Jika Anda tidak bisa melakukan validasi domain menggunakan email,
              Anda dapat membuktikan kepemilikan domain Anda dengan membuat
              Record DNS. <br />Untuk menggunakan metode ini, Anda harus dapat
              mengedit DNS domain untuk membuat catatan baru dengan tipe TXT,
              dengan nilai unik yang akan kami berikan nanti.
            </p>
            <p v-if="form.step2.metode_validasi === 'HTTP'">
              CA (Certificate Authority) akan membuat kode verifikasi spesifik
              untuk domain Anda. <br />Letakkan kode verifikasi tersebut di
              sebuah file teks yang sudah ditentukan. Pastikan file teks
              tersebut bisa diakses lewat http.
            </p>
          </b-alert>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import widgetWizardSSL from "@/views/partials/widgets/rw/widgetWizardSSL";
import RWCard from "@/views/partials/content/Portlet.vue";
import { ApiURL, notif, util, loading } from "@/common/mixins/general";
import { country, web_server } from "@/common/config";

export default {
  name: "daosign-index",
  mixins: [ApiURL, notif, util, loading],
  components: { RWCard, widgetWizardSSL },

  /**
   * --------------------------------------------------
   * serviceid : product id dari SSL
   * status : status ssl active or etc
   * profil : profil current user for autocomple step3
   * --------------------------------------------------
   */
  props: {
    serviceid: {
      required: true
    },
    status: {
      required: true,
      default: {}
    },
    profil: {
      required: true,
      default: {}
    }
  },

  /**
   * --------------------------------------------------
   * step : step yang sedang terbuka
   * step_master : progress step sebenarnya yg telah diisi
   * --------------------------------------------------
   */
  data() {
    return {
      step: 1,
      step_master: 1,
      account: {},
      form: {
        step1: {
          web_server: "31",
          csr: ""
        },
        step2: {
          metode_validasi: null,
          penerima_email: ""
        },
        step3: {
          name: "",
          email: "",
          telepon: "",
          phone: "",
          dialcode: "62",
          company: "",
          no_usaha: "",
          departemen: "",
          jabatan: "",
          address: "",
          pos_code: ""
        }
      },
      metode_validasi: {
        options: [
          { value: null, text: "-Pilih-" },
          { value: "EMAIL", text: "E-MAIL Validasi (recommended)" },
          { value: "CNAME", text: "CNAME" },
          { value: "HTTP", text: "HTTP" }
        ]
      },
      penerima_email: {
        options: []
      },
      web_server: {
        options: web_server
      },
      country: country,
      status_request: "awaiting configuration",

      /**
       * --------------------------------------------------
       * data dibawah terisi jika konfirgurasi SSL telah disubmit
       * untuk menampung cara validasi domain nya
       * --------------------------------------------------
       */
      config_dcvmethod: {
        /**
         * --------------------------------------------------
         * {cname:"x",cname_record:""}
         * --------------------------------------------------
         */
        CNAME: {},

        /**
         * --------------------------------------------------
         * {path, line1, line2}
         * --------------------------------------------------
         */
        HTTP: {},
        errorMesage: "error default",
        loading: true
      },

      /**
       * --------------------------------------------------
       * jika ssl sudah issued, download cert
       * --------------------------------------------------
       */
      cert: {
        crt: {},
        success: false,
        loading: true,
        errorMessage: ""
      }
    };
  },
  methods: {
    clickStepDirect(step) {
      if (step <= this.step_master) {
        this.step = step;
      }
    },
    /**
     * --------------------------------------------------
     * get an account ssl and parsing to child
     * --------------------------------------------------
     */
    getAccountSSL: function() {
      this.http
        .get(this.end.ssl.account, this.serviceid)
        .then(({ data }) => {
          let record = data.data.account;
          this.account = record;

          /**
           * --------------------------------------------------
           * jika vendor tersedia (sudah melakukan konfigurasi ssl)
           * --------------------------------------------------
           */
          if (record.vendor) {
            this.status_request = record.vendor.status_request;
            this.form.step1.csr = record.vendor.csr;
            this.form.step1.web_server = record.vendor.webserver_type;
            this.form.step2.metode_validasi = record.vendor.dcvmethod;
            this.form.step2.penerima_email = record.vendor.dcvemail;
            // jika csr ada maka step2
            if (record.vendor.csr) {
              this.step = 2;
              this.step_master = 2;
            }
            // jika dcvmethod ada maka step3
            if (record.vendor.dcvmethod !== null) {
              this.step = 3;
              this.step_master = 3;
            }

            if (record.vendor.ordnum !== null || record.vendor.ordnum !== "") {
              if (this.status_request === "pending validation") {
                this.getConfigDcvMethod(record.vendor.dcvmethod);
              } else if (this.status_request === "issued") {
                this.getCertificate();
              }
            }
          }
          this.generateEmail();
        })
        .catch(() => {
          // this.account.success = false;
        });
    },

    /**
     * --------------------------------------------------
     * send json csr & webserver type
     * --------------------------------------------------
     */
    submitStepOne: function() {
      if (this.form.step1.csr !== "") {
        if (this.form.step1.csr.includes("BEGIN CERTIFICATE")) {
          this.http
            .post(this.api.product.ssl.manage.sectigo.index, {
              step: 1,
              csr: this.form.step1.csr,
              serviceid: this.serviceid,
              webserver: this.form.step1.web_server
            })
            .then(({ data }) => {
              // notif bahwa domain csr tidak menggunakan www
              if (typeof data.data.www !== "undefined") {
                this.mx_swal_confirm(
                  data.data.www,
                  "Konfirmasi",
                  "warning"
                ).then(() => {
                  this.step = 2;
                });
              } else {
                this.step = 2;
                this.mx_swal("CSR berhasil dikirim.", "Success", "success");
              }

              this.step_master = 2;
            })
            .catch(error => {
              this.mx_swal(error.message);
            });
        } else {
          this.mx_swal("Format CSR salah.");
        }
      } else {
        this.mx_swal("Isikan CSR terlebih dahulu.");
      }
    },

    /**
     * --------------------------------------------------
     * generate email jika dcvmethod = email for step2
     * --------------------------------------------------
     */
    generateEmail: function() {
      this.penerima_email.options = [];
      let prefix = [
        "admin",
        "administrator",
        "hostmaster",
        "postmaster",
        "webmaster"
      ];

      let email = [{ value: "", text: "Pilih Penerima Email" }];
      for (const key in prefix) {
        let mail = `${prefix[key]}@${this.status.domain}`;
        email.push({ value: mail, text: mail });
      }
      this.penerima_email.options = email;
    },

    /**
     * --------------------------------------------------
     * send dcv method email, http or cname
     * --------------------------------------------------
     */
    submitStepTwo: function() {
      if (
        this.form.step2.metode_validasi !== null ||
        this.form.step2.metode_validasi !== ""
      ) {
        let step2 = this.form.step2;
        if (step2.metode_validasi === "EMAIL" && step2.penerima_email === "") {
          this.mx_swal("Silahkan pilih penerima email terlebih dahulu");
        } else {
          this.http
            .post(this.api.product.ssl.manage.sectigo.index, {
              step: 2,
              serviceid: this.serviceid,
              metode: step2.metode_validasi,
              email: step2.penerima_email
            })
            .then(() => {
              this.step = 3;
              this.step_master = 3;
              this.mx_swal(
                "Dcv method berhasil diproses.",
                "Success",
                "success"
              );
            })
            .catch(error => {
              this.mx_swal(error.message);
            });
        }
      } else {
        this.mx_swal("Silahkan pilih metode validasi terlebih dahulu.");
      }
    },

    /**
     * --------------------------------------------------
     * submit proses step 3
     * --------------------------------------------------
     */
    submitStepThree: function() {
      this.mx_swal_confirm(
        "Apakah semua data sudah benar ?",
        "Perhatian",
        "warning"
      ).then(() => {
        let payload = this.form.step3;
        payload.step = 3;
        payload.serviceid = this.serviceid;
        this.http
          .post(this.api.product.ssl.manage.sectigo.index, payload)
          .then(() => {
            this.mx_swal(
              "Konfigurasi SSL berhasil diproses.",
              "Success",
              "success"
            );
            this.$router.push(`/product/ssl/${this.serviceid}`);
          })
          .catch(error => {
            this.mx_swal(error.message);
          });
      });
    },

    /**
     * --------------------------------------------------
     * get konfigurasi dcv method for http and cname
     * method email tidak karena tidak ada konfigurasi khusus
     * @param dcvMethod String HTTP | CNAME
     * --------------------------------------------------
     */
    getConfigDcvMethod: function(dcvMethod) {
      let dcv = ["HTTP", "CNAME"];
      if (
        dcv.includes(dcvMethod) &&
        this.status_request === "pending validation"
      ) {
        this.http
          .post(this.api.product.ssl.manage.sectigo.configdcvmethod, {
            serviceid: this.serviceid
          })
          .then(({ data }) => {
            this.config_dcvmethod[dcvMethod] = data.data.record;
          })
          .catch(error => {
            this.config_dcvmethod.errorMesage = error.message;
          })
          .finally(() => {
            this.config_dcvmethod.loading = false;
          });
      }
    },

    /**
     * --------------------------------------------------
     * ganti methode validasi, menggunakan model step2
     * --------------------------------------------------
     */
    changeDcvMethod() {
      if (this.form.step2.metode_validasi !== "") {
        let step2 = this.form.step2;
        if (step2.metode_validasi === "EMAIL" && step2.penerima_email === "") {
          this.mx_swal("Silahkan pilih penerima email terlebih dahulu.");
        } else {
          if (step2.metode_validasi !== this.account.vendor.dcvmethod) {
            this.mx_loading();
            this.http
              .post(this.api.product.ssl.manage.sectigo.changedcvmethod, {
                serviceid: this.serviceid,
                method: step2.metode_validasi,
                email: step2.penerima_email
              })
              .then(() => {
                this.getAccountSSL();
                this.mx_swal(
                  "Berhasil mengubah methode validasi menjadi " +
                    step2.metode_validasi,
                  "Success",
                  "success"
                );
              })
              .catch(error => {
                this.step2.metode_validasi = this.account.vendor.dcvmethod;
                this.mx_swal(error.message);
              })
              .finally(() => {
                this.mx_loading(false);
              });
          } else {
            this.mx_swal(
              "Methode validasi yang dipilih sama seperti saat ini."
            );
          }
        }
      } else {
        this.mx_swal("Silahkan pilih metode validasi terlebih dahulu.");
      }
    },

    /**
     * --------------------------------------------------
     * request sertifikat ke sectigo
     * --------------------------------------------------
     */
    getCertificate: function() {
      if (this.status_request === "issued") {
        this.http
          .get(
            this.api.product.ssl.manage.sectigo.certificate +
              "/" +
              this.serviceid
          )
          .then(({ data }) => {
            this.cert.crt = data.data.record;
            this.cert.success = true;
          })
          .catch(error => {
            this.cert.errorMessage = error.message;
            this.cert.success = false;
          })
          .finally(() => {
            this.cert.loading = false;
          });
      }
    },

    /**
     * --------------------------------------------------
     * button donwload certificate SSL sectigo
     * --------------------------------------------------
     */
    downloadCert: function(crt_type = "crt") {
      let txt = "Halo kak apakabar ? :)";
      if (crt_type === "crt") {
        txt = this.cert.crt.crt;
      } else if (crt_type === "intermediate") {
        txt = this.cert.crt.intermediate;
      } else if (crt_type === "root") {
        txt = this.cert.crt.root;
      }
      this.mx_download_txt(txt, `sectigo_${crt_type}`);
    },

    /**
     * --------------------------------------------------
     * for copy something
     * --------------------------------------------------
     */
    clone: function(text, message = "text") {
      this.mx_copy_text(text);
      this.mx_toast(`Copy ${message} berhasil`, "Success", {
        variant: "success"
      });
    }
  },
  mounted() {
    //this.form.csr = this.account.vendor.csr;
    this.getAccountSSL();
    this.form.step3.name = this.profil.fullname;
    this.form.step3.email = this.profil.email;
    this.form.step3.telepon = this.profil.phonenumber;
    this.form.step3.company = this.profil.companyname;
  }
};
</script>
