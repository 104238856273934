<template>
  <div>
    <template v-if="loading">
      <b-card>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <br />
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <br />
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </b-card>
    </template>
    <template v-else>
      <b-alert variant="danger" show v-if="!status.success">{{
        status.error.message
      }}</b-alert>
      <template v-else>
        <component
          :is="view"
          :serviceid="id"
          :status="status.record"
          :profil="user"
        ></component>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import entrust from "@/views/pages/product/ssl/manage/entrust/entrust-index";
import rwdaousign from "@/views/pages/product/ssl/manage/sectigo/sectigo-index";
import globalsign from "@/views/pages/product/ssl/manage/globalsign/globalsign-index";
import nonActive from "@/views/pages/product/slot/slotLayananNonActive.vue";

import { ApiURL } from "@/common/mixins/general";

export default {
  name: "index",
  mixins: [ApiURL],
  components: {
    entrust,
    rwdaousign,
    globalsign,
    nonActive
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      module: "",
      status: {
        record: {},
        loading: true,
        success: false,
        error: {}
      },
      loading: true
    };
  },
  computed: {
    view() {
      return this.module;
    },
    ...mapGetters(["user"])
  },
  methods: {
    /**
     * --------------------------------------------------
     * request status SSL aktif / tidak
     * yang selanjutnya akan diarahkan ke page aktif / tidak
     * --------------------------------------------------
     */
    getStatusSSL: function() {
      return new Promise((resolve, reject) => {
        this.http
          .get(this.end.ssl.status, this.id)
          .then(({ data }) => {
            this.status.record = data.data.status;
            this.status.success = true;
            resolve(data);
          })
          .catch(error => {
            this.status.error = error;
            reject(error);
          });
      });
    }
  },
  mounted() {
    this.getStatusSSL()
      .then(data => {
        let record = data.data.status;
        if (record.status === "Active") {
          let modules = ["rwdaousign", "rwentrust", "srsxssl"];
          if (modules.includes(record.module)) {
            this.module = record.module;
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Product", route: "product" },
              { title: "SSL", route: "product/ssl" },
              { title: record.domain, route: `product/ssl/${this.id}` },
              { title: "Manage" }
            ]);
          } else {
            this.status.error.message = `Sorry, module ${record.module} not support`;
          }
        } else {
          this.module = "nonActive";
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>

<style>
.form-group {
  margin-bottom: 1rem;
}
</style>
